import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { ExploreHub } from "./components/explore-hub";
import Layout from "./components/layout";
import { ProfileEdit } from "./components/user/profile-edit";
import { CommunityView } from "./components/communities/community-profile-view";
import { ManageAdmins } from "./components/communities/manage-admins";
import { EventCreationForm } from "./components/events/components/create-event-form";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { LoadingSpinner } from "./shadcn-components/loading-spinner";
import { EventDetailsView } from "./components/events/components/event-details-view";
import SearchResults from "./components/communities/search-results";
import searchStore from "./stores/search-store";
import ManageEventForm from "./components/events/components/manage-event";
import { GuestList } from "./components/events/components/guests-list";
import { ExploreContent } from "./explore/components/explore-content";
import { Landing } from "./components/landing/landing";
import signupStore from "./stores/signup-store";
import { AboutUs } from "./components/landing/about-us";
import TermsAndConditions from "./components/landing/terms-and-conditions";
import PrivacyPolicy from "./components/landing/privacy-policy";
import { ContactUs } from "./components/landing/contact-us";
import { LoginScreen } from "./components/user/join";
import { OTPVerificationScreen } from "./components/user/otp-verification";
import { ProfileForm } from "./components/user/onboarding-profile";
import { CalendarView } from "./components/calendar";
import { authStore } from "./stores/auth-store";
import CreateCommunityForm from "./components/communities/create-community-form";
import { ManageCommunityForm } from "./components/communities/manage-community";
import { AboutUsTemp } from "./components/landing2";
export const PUBLIC_ROUTES = [
  "/about-us",
  "/terms",
  "/privacy",
  "/contact",
  "/",
  "/explore",
  "/calendar",
  "/c",
  "/search",
  "/e",
] as const;

export const PROTECTED_ROUTES = {
  exact: [
    "/c/new",
    "/me",
    // ,"/e/new"
  ] as const, // Note the 'as const' here
  patterns: ["/*/manage"] as const,
};

// Create a type from the exact routes array
type ExactProtectedRoute = (typeof PROTECTED_ROUTES.exact)[number];

export const AUTH_ROUTES = ["/join", "/verify", "/onboarding-profile"] as const;
const RequireAuth = observer(({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, initialized } = authStore;
  const location = useLocation();

  if (!initialized) {
    return <LoadingSpinner />;
  }

  const isAuthRoute = AUTH_ROUTES.some((route) => location.pathname === route);

  const isPublicRoute = PUBLIC_ROUTES.some((route) =>
    location.pathname.startsWith(route)
  );

  const isProtectedRoute =
    PROTECTED_ROUTES.exact.includes(location.pathname as ExactProtectedRoute) ||
    PROTECTED_ROUTES.patterns.some((pattern) => {
      const regexPattern = pattern.replace("*", "[^/]+");
      return new RegExp(`^${regexPattern}$`).test(location.pathname);
    });

  if (
    !isAuthenticated &&
    (isProtectedRoute || (!isPublicRoute && !isAuthRoute))
  ) {
    return <Navigate to="/join" state={{ from: location }} replace />;
  }

  if (isAuthenticated && isAuthRoute) {
    return <Navigate to="/explore" replace />;
  }

  return <>{children}</>;
});

const App = observer(() => {
  const { getAllClientSideData } = signupStore;

  useEffect(() => {
    const init = async () => {
      await authStore.initializeAuth();
      await getAllClientSideData();
    };
    init();
  }, []);

  useEffect(() => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const viewport = document.head.querySelector(
      'meta[name="viewport"]'
    ) as HTMLMetaElement;

    if (viewport) {
      if (iOS) {
        viewport.setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1"
        );
      } else {
        viewport.setAttribute("content", "width=device-width, initial-scale=1");
      }
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
          {/* Redirect root to about-us */}
          <Route path="/" element={<Navigate to="/about-us" replace />} />
          <Route path="/about-us" element={<AboutUsTemp />} />

          {/* Other essential standalone routes */}
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />

          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/about-us" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
});
export default App;
